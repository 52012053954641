
let TEXTS = {
    'EN': {
        'HOME': 'Home',
        'ADVENTURE': 'Adventure',
        'ARCADE': 'Arcade',
        'CLASSICS': 'Classics',
        'GIRLS': 'Girls',
        'SPORTS': 'Sports',
        'STRATEGY': 'Strategy',
        'LATESTGAMES': 'Latest Games',
        'ACTIONGAMES': 'Action Games',
        'ADVENTUREGAMES': 'Adventure Games',
        'CLASSICSGAMES': 'Classics Games',
        'SPORTGAMES': 'Sports Games',
        'GIRLSGAMES': 'Girls Games',
        'STRATEGYGAMES': 'Strategy Games',
        'TERMS':'Terms & Conditions',
        'FAQ':'FAQ',
        'PRIVACY&POLICY':'Privacy & Policy',
        'CONTACT':'Contact',
        'COPYRIGHT':'Copyright ©2021 Playhub. All rights reserved.'
    },
    'HR': {
        'HOME': 'Početna',
        'ADVENTURE': 'Avantura',
        'ARCADE': 'Arkada',
        'CLASSICS': 'Klasici',
        'GIRLS': 'Djevojke',
        'SPORTS': 'Sport',
        'STRATEGY': 'Strategija',
        'LATESTGAMES': 'Najnovije igre',
        'ACTIONGAMES': 'Akcijske igre',
        'ADVENTUREGAMES': 'Avanturističke igre',
        'CLASSICSGAMES': 'Klasične igre',
        'SPORTGAMES': 'Sportske igre',
        'GIRLSGAMES': 'Igre za djevojke',
        'STRATEGYGAMES': 'Strateške igre',
        'TERMS':'Uvjeti i odredbe',
        'FAQ':'FAQ',
        'PRIVACY&POLICY':'Privatnost i politika',
        'CONTACT':'Kontakt',
        'COPYRIGHT':'Copyright © 2021 Playhub. Sva prava pridržana.'
    },    
    'SB': {
        'HOME': 'Почетна',
        'ADVENTURE': 'Авантура',
        'ARCADE': 'Аркада',
        'CLASSICS': 'Класика',
        'GIRLS': 'Девојке',
        'SPORTS': 'Спорт',
        'STRATEGY': 'Стратегија',
        'LATESTGAMES': 'Најновије игре',
        'ACTIONGAMES': 'Акционе игре',
        'ADVENTUREGAMES': 'Авантуристичке игре',
        'CLASSICSGAMES': 'Класичне игре',
        'SPORTGAMES': 'Спортске игре',
        'GIRLSGAMES': 'Игре за девојке',
        'STRATEGYGAMES': 'Стратешке игре',
        'TERMS':'Услови и одредбе',
        'FAQ':'ФАК',
        'PRIVACY&POLICY':'Приватност и политика',
        'CONTACT':'Контакт',
        'COPYRIGHT':'Цопиригхт © 2021 Плаихуб. Сва права задржана.'
    },
    'RO': {
        'HOME': 'Acasă',
        'ADVENTURE': 'Aventură',
        'ARCADE': 'Arcadă',
        'CLASSICS': 'Clasici',
        'GIRLS': 'Fetelor',
        'SPORTS': 'Sport',
        'STRATEGY': 'Strategie',
        'LATESTGAMES': 'Ultimele jocuri',
        'ACTIONGAMES': 'Jocuri de acțiune',
        'ADVENTUREGAMES': 'Jocuri de aventură',
        'CLASSICSGAMES': 'Jocuri clasice',
        'SPORTGAMES': 'Jocuri sportive',
        'GIRLSGAMES': 'Jocuri pentru fete',
        'STRATEGYGAMES': 'Jocuri de strategie',
        'TERMS':'termeni si conditii',
        'FAQ':'FAQ',
        'PRIVACY&POLICY':'Confidențialitate și politică',
        'CONTACT':'Contact',
        'COPYRIGHT':'Drepturi de autor © 2021 Playhubu. Toate drepturile rezervate.'
    },
    'SW': {
        'HOME': 'Zuhause',
        'ADVENTURE': 'Abenteuer',
        'ARCADE': 'Arkade',
        'CLASSICS': 'Klassiker',
        'GIRLS': 'Mädchen',
        'SPORTS': 'Sport',
        'STRATEGY': 'Strategie',
        'LATESTGAMES': 'Neueste Spiele',
        'ACTIONGAMES': 'Action-Spiele',
        'ADVENTUREGAMES': 'Abenteuerspiele',
        'CLASSICSGAMES': 'Klassiker Spiele',
        'SPORTGAMES': 'Sportspiele',
        'GIRLSGAMES': 'Mädchenspiele',
        'STRATEGYGAMES': 'Strategiespiele',
        'TERMS':'Geschäftsbedingungen',
        'FAQ':'häufig gestellte Fragen',
        'PRIVACY&POLICY':'Datenschutz-Bestimmungen',
        'CONTACT':'Kontakt',
        'COPYRIGHT':'Copyright © 2021 Playhub. Alle Rechte vorbehalten.'
    },
    'AR': {
        'HOME': 'الرئيسية',
        'ADVENTURE': 'المغامرة',
        'ARCADE': 'الاكشن',
        'CLASSICS': 'الكلاسيكيات',
        'GIRLS': 'البنات',
        'SPORTS': 'الرياضة',
        'STRATEGY': 'الاستراتجية',
        'LATESTGAMES': 'أحدث الألعاب',
        'ACTIONGAMES': 'ألعاب الأكشن',
        'ADVENTUREGAMES': 'ألعاب المغامرات',
        'CLASSICSGAMES': 'ألعاب الكلاسكية',
        'SPORTGAMES': 'ألعاب الرياضية',
        'GIRLSGAMES': 'ألعاب البنات',
        'STRATEGYGAMES': 'ألعاب الإستراتجية',
        'TERMS':'الشروط والاحكام',
        'FAQ':'الاسئلة الشائعة',
        'CONTACT':'للتواصل',
        'COPYRIGHT':'حقوق النشر © 2021 Playhub. كل الحقوق محفوظة.'
    },
    'BA': {
        'HOME': 'Dom',
        'ADVENTURE': 'Avantura',
        'ARCADE': 'Arkada',
        'CLASSICS': 'Klasika',
        'GIRLS': 'Devojke',
        'SPORTS': 'Sportovi',
        'STRATEGY': 'Strategija',
        'LATESTGAMES': 'Najnovije igre',
        'ACTIONGAMES': 'Akcijske igre',
        'ADVENTUREGAMES': 'Avanturističke igre',
        'CLASSICSGAMES': 'Klasične igre',
        'SPORTGAMES': 'Sportske igre',
        'GIRLSGAMES': 'Igre za djevojke',
        'STRATEGYGAMES': 'Strateške igre',
        'TERMS':'Uvjeti i odredbe',
        'FAQ':'Često Postavljena Pitanja',
        'PRIVACY&POLICY':'Politika privatnosti',
        'CONTACT':'Kontakt',
        'COPYRIGHT':'Copyright ©2021 Playhub. Sva prava zadržana.'
    },
    'BG': {
        'HOME': 'У дома',
        'ADVENTURE': 'ПРИКЛЮЧЕНИЕ',
        'ARCADE': 'АРКАДА',
        'CLASSICS': 'КЛАСИКА',
        'GIRLS': 'МОМИЧЕТА',
        'SPORTS': 'СПОРТ',
        'STRATEGY': 'СТРАТЕГИЯ',
        'LATESTGAMES': 'ПОСЛЕДНИ ИГРИ',
        'ACTIONGAMES': 'АКЦИОННИ ИГРИ',
        'ADVENTUREGAMES': 'ПРИКЛЮЧИТЕЛНИ ИГРИ',
        'CLASSICSGAMES': 'КЛАСИЧЕСКИ ИГРИ',
        'SPORTGAMES': 'СПОРТНИ ИГРИ',
        'GIRLSGAMES': 'ИГРИ ЗА МОМИЧЕТА',
        'STRATEGYGAMES': 'ИГРИ ЗА СТРАТЕГИЯ',
        'TERMS':'УСЛОВИЯ',
        'FAQ':'често задавани въпроси',
        'PRIVACY&POLICY':'КОНФИДЕНЦИАЛНОСТ И ПОЛИТИКА',
        'CONTACT':'КОНТАКТ',
        'COPYRIGHT':'Copyright © 2020 Playhub. Всички права запазени.'
    }

}

function get_text(lang_code, text_code, var_dict) {


    if (!(lang_code in TEXTS)) {
        lang_code = 'EN';
    }

    var t = TEXTS[lang_code][text_code];
    for (var k in var_dict) {
        if (t.indexOf("{" + k + "}") >= 0) {
            t = t.replace("{" + k + "}", var_dict[k]);
        }
    }

    return t;
}

export default get_text;